import React, {useState, useEffect} from "react";

export default function useConfig(name = "config", init = {}) {
    const [config, setConfig] = useState(localStorage.getItem(name) && JSON.parse(localStorage.getItem(name)) || init);
    const [loading, setConfigLoading] = useState(false);

    function save(data) {
        var json = JSON.stringify(data);
        localStorage.setItem(name, json);
        restore();
    }

    function restore () {
        var newConfig = Object.assign({}, JSON.parse(localStorage.getItem(name)));
        setConfig(newConfig);
    }

    useEffect(()=>{
        setConfigLoading(true);
        if(localStorage.getItem(name)) {
            restore();
        }
        setConfigLoading(false);
    },[])

    return {
        save,
        restore,
        config,
        loading
    }

}