// https://covid-19-statistics.p.rapidapi.com/reports?region_province=Beijing&iso=CHN&region_name=China&date=2020-03-14&q=China%20Beijing

import React, { useState, useEffect } from "react";

import moment from "moment";

const API = "https://covid-19-statistics.p.rapidapi.com";
const KEY = "2950419059msh9b6cb7c4d5fa0f7p14fa8djsn817314303383";
const HOST = "covid-19-statistics.p.rapidapi.com";

export const DATE_FORMAT = "YYYY-MM-DD";
export const NOW = moment().format(DATE_FORMAT);
export const YESTERDAY = moment()
  .add(-1, "days")
  .format(DATE_FORMAT);

function objToString(obj) {
  var str = [];
  for (var key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] != null && obj[key] !== "") {
      str.push(`${key}=${obj[key]}`);
    }
  }

  return str.length > 0 ? `?${str.join("&")}` : "";
}

const report = props => `${API}/reports${objToString(props)}`;

const province = regionCode => `${API}/provinces?iso=${regionCode}`;


export const createQuery = (regions, region, province, date = YESTERDAY) => {
  return regions[region]
    ? Object.assign(regions[region][province], { date })
    : { date, region: "Canada", province: "Alberta" };
};




function mean(array) {
  return array.reduce((a, b) => {
    a += b;
    return a;
  }, 0) / array.length;
}

function median(array) {
  var sorted = array.sort();
  var middle = sorted.length / 2;
  if (middle % 2 === 0) {
    var min = Math.floor(middle);
    var max = Math.ceil(middle);
    return (sorted[min] + sorted[max]) / 2
  } else {
    var index = Math.floor(middle);
    return sorted[index];
  }
}

function mode(array) {
  var most = 0;
  var mode = 0;

  var occurence = array.reduce((a, b) => {
    var fix = b.toFixed(4);
    if (!a[fix]) {
      a[fix] = 0;
    }
    a[fix]++;
    return a;
  }, {})

  Object.keys(occurence).map((key) => {
    if (occurence[key] > most) {
      most = occurence[key];
      mode = parseInt(key, 10);
    }
  })
  return mode;
}

function range(array) {
  var sorted = array.sort();
  return sorted[sorted.length - 1] - sorted[0];
}

function extractValuesFromKey(array, key) {
  return array.map(r => r[key]);
}

function fatalityValues(regions) {

  const fatality_rates = extractValuesFromKey(regions, "fatality_rate");

  return {
    fatality_mean: mean(fatality_rates),
    fatality_median: median(fatality_rates),
    fatality_mode: mode(fatality_rates),
    fatakuty_range: range(fatality_rates),
  }

}



export function GlobalTally(regions) {

  const tally = regions.reduce((a, r) => {
    Object.keys(a).map(key => {
      a[key] += r[key];
    });

    return a;
  }, {
    confirmed: 0,
    recovered: 0,
    deaths: 0,
    confirmed_diff: 0,
    deaths_diff: 0,
    recovered_diff: 0,
    active: 0,
    active_diff: 0
  })

  Object.assign(tally, fatalityValues(regions));

  return tally;
}

export const useReport = ({
  province: region_province,
  iso,
  name: region_name,
  date
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [best, setBest] = useState([])
  const [worst, setWorst] = useState([])

  useEffect(() => {
    setLoading(true);
    fetch(
      report({
        region_province,
        iso,
        region_name,
        date
      }),
      {
        method: "GET",
        headers: {
          "x-rapidapi-host": HOST,
          "x-rapidapi-key": KEY
        }
      }
    )
      .then(r => r.json())
      .then(({ data, error }) => {
        setData(error || data);
        setLoading(false);
      })
      .catch(e => {
        console.warn(e);
        setLoading(false);
      });
  }, [region_province, iso, region_name, date]);

  return { loading, data };
};

export const useProvince = iso => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch(province(iso), {
      method: "GET",
      headers: {
        "x-rapidapi-host": HOST,
        "x-rapidapi-key": KEY
      }
    })
      .then(r => r.json())
      .then(({ data, error }) => {
        setData(error || data);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  }, [iso]);

  return { loading, data };
};

export const useRegions = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch(report(), {
      method: "GET",
      headers: {
        "x-rapidapi-host": HOST,
        "x-rapidapi-key": KEY
      }
    })
      .then(r => r.json())
      .then(({ data, error }) => {
        setData(error || data);
        setLoading(false);
      })
      .catch(e => {
        console.warn(e);
        setLoading(false);
      });
  }, []);

  return { loading, data };
};

export const reduceRegions = regions =>
  regions.reduce((a, { region }) => {
    if (!a.includes(region.iso)) {
      a.push(region.iso);
    }
    return a;
  }, []);

export const collectRegions = regions =>
  regions.reduce((a, { region }) => {
    if (!a[region.name]) {
      a[region.name] = [];
    }
    a[region.name].push(region);
    return a;
  }, {});
