import ReactDOM from "react-dom";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  Suspense,
  useCallback
} from "react";

import * as THREE from "three/src/Three";

import moment from "moment";

import Datepicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import CovidModel from "./Assets/covid_model";

import { Report, DATA_FLAGS as DF, ToggleReport } from "./Report";

import useConfig from "./useConfig";

import { Canvas, Dom, useFrame, useThree } from "react-three-fiber";

import {
  useReport,
  useRegions,
  collectRegions,
  createQuery,
  NOW,
  YESTERDAY,
  DATE_FORMAT
} from "./useCovid";

import "./style.scss";

import Effects from "./Effects";


import useMouse from "./useMouse";
import { MathUtils } from "three/src/Three";


const SelectKey = ({
  values,
  selected,
  onChange = e => console.log(e.currentTarget.value)
}) => (
    <select onChange={onChange} value={selected}>
      {values.map((v, i) => (
        <option key={v + i} value={v}>
          {v}
        </option>
      ))}
    </select>
  );

const SelectIndex = ({
  values,
  selected,
  onChange = e => console.log(e.currentTarget.value)
}) => (
    <select onChange={onChange} value={selected}>
      {values.map((v, i) => (
        <option key={v} value={i}>
          {v}
        </option>
      ))}
    </select>
  );


const DataViz = ({defaultRegion, defaultProvince, defaultFlags, save}) => {
  const isMobile = useMemo(() => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent), []);



  const [open, setOpen] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(defaultRegion);
  const [selectedProvince, setSelectedProvince] = useState(defaultProvince);
  const [selectedDate, setSelectedDate] = useState(YESTERDAY);
  const [dataFlags, setDataFlags] = useState(defaultFlags);
 
  const { loading: regionsLoading, data: regions } = useRegions();
  const collectedRegions = useMemo(() => collectRegions(regions), [regions]);

  const region = useMemo(
    () => createQuery(
          collectedRegions,
          selectedRegion,
          selectedProvince,
          selectedDate
        ),
    [collectedRegions, selectedRegion, selectedProvince, selectedDate]);

    
    const { loading: reportLoading, data: report } = useReport(region);
  

  const menu_ready = useMemo(() => open && collectedRegions, [open, collectedRegions]);



  const setFlag = useCallback((flag, set) => {
    var newFlags = dataFlags.slice();
    if (set) {
      newFlags.push(flag);
    } else {
      newFlags = newFlags.filter(f => f !== flag);
    }
    setDataFlags(newFlags);
  });

  
  const setAsDefault = useCallback(() => {
    save({
      region: selectedRegion,
      province: selectedProvince,
      flags: dataFlags,
    });
  });

  return (
     <>
      <div id="menu" className={open ? "open" : ""}>
        {menu_ready && (
          <>
            <div className="grid">
              <ToggleReport flags={dataFlags} onClick={setFlag} />
              <SelectKey
                values={Object.keys(collectedRegions).sort()}
                selected={selectedRegion}
                onChange={e => {
                  setSelectedProvince(0);
                  setSelectedRegion(e.currentTarget.value);
                  if (collectedRegions[selectedRegion] && collectedRegions[e.currentTarget.value].length === 1) {
                    setOpen(false);
                  }
                }}
              />
              {collectedRegions[selectedRegion] && collectedRegions[selectedRegion].length > 1 && (
                <SelectIndex
                  values={collectedRegions[selectedRegion].map(r =>
                    r.province !== "" ? r.province : "All"
                  )}
                  selected={selectedProvince}
                  onChange={e => {
                    setOpen(false);
                    setSelectedProvince(parseInt(e.currentTarget.value, 10));
                  }}
                />
              )}
              <Datepicker
                selected={moment(selectedDate, DATE_FORMAT).toDate()}
                onChange={e => setSelectedDate(moment(e).format(DATE_FORMAT))}
                dateFormat={"yyyy-MM-dd"}
                min={moment("2020-01-22", DATE_FORMAT).toDate()}
                max={moment(NOW, DATE_FORMAT).toDate()}
                showDisabledMonthNavigation
              />

              <div className="toggle">
                <a href="https://twitter.com/intent/tweet?screen_name=MatthewWillox&ref_src=twsrc%5Etfw" className="twitter-mention-button" data-show-count="false">Like this? Say Hi! @MatthewWillox</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
              </div>

              <button onClick={setAsDefault}>Set Current Config as Default</button>

            </div>
          </>
        )}
        
        <button className="icon-button" onClick={() => setOpen(!open)}>
          <span aria-label="Settings" role="img">
            ⚙️
          </span>
        </button>
      </div>

      <div id="dashboard" className={!isMobile ? "chroma" : ""}>
        {report && report[0] && <Report {...report[0]} flags={dataFlags} />}
      </div>

      <div id="date" className={!isMobile ? "chroma" : ""}>
        <h1>COVID YESTERDAY</h1>
        {!regionsLoading && !reportLoading && <h2>
          {`${region.province !== "" ? region.province + "," : ""}`}{" "}
          {selectedRegion}
        </h2>}

        {(regionsLoading || reportLoading) && <h2>
          L O A D I N G
        </h2>}
        
      </div>
    </>
  );
};




function polarRandom(scale = 1) {
  return (0.5 - Math.random()) * 2 * scale;
}


function Cloud({ amount, scale, size, color }) {
  const points = useMemo(() => {

    var geometry = new THREE.BufferGeometry();
    var vertices = [];

    //var sprite = new THREE.TextureLoader().load( 'textures/sprites/disc.png' );

    for (var i = 0; i < amount; i++) {

      var x = polarRandom(scale);
      var y = polarRandom(scale);
      var z = polarRandom(scale);

      vertices.push(x, y, z);

    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    var material = new THREE.PointsMaterial({
      color: color,
      size: size,
      sizeAttenuation: true,
      // map: sprite, 
      //alphaTest: 0.5,
      transparent: true
    });

    return <points
      geometry={geometry}
      material={material}
    />

  }, [amount, color, size, scale])

  return <>{points}</>

}

function PointField(props) {
  const field = useRef();
  const { amount = 10000, scale = 40, size = 0.14 } = props;

  useFrame(() => {
    field.current.rotation.y += 0.001;
    field.current.rotation.z = Math.sin(field.current.rotation.y);
  });

  return (
    <group {...props} ref={field}>
      <Cloud color={0x5588BB} amount={amount / 3} scale={scale} size={0.1 + (0.1 * Math.random())} />
      <Cloud color={0x5544DD} amount={amount / 3} scale={scale} size={0.1 + (0.1 * Math.random())} />
      <Cloud color={0x555577} amount={amount / 3} scale={scale} size={0.1 + (0.1 * Math.random())} />
    </group>
  );
}

const Virus = props => {
  const virus = useRef();
  const [xr, setxr] = useState(0.0001);
  //const [yr, setyr] = useState((0.5 - Math.random()) * 0.005);
  const [zr, setzr] = useState((0.5 - Math.random()) * 0.005);
  useFrame(({ camera, scene }) => {
    virus.current.rotation.x += Math.cos(Date.now() * xr) * 0.005;
    virus.current.rotation.y += zr;
    virus.current.position.z = Math.sin(Date.now() * xr) * 0.01;
    //virus.current.rotation.y += Math.sin(Date.now() * zr) * 0.005;
  });
  return (
    <group {...props} ref={virus}>
      <CovidModel />
    </group>
  );
};

function Camera(props) {
  const ref = useRef();
  const rig = useRef();
  var body = useRef(document.body);
  const { setDefaultCamera } = useThree();
  const { vX, vY } = useMouse(body)
  // Make the camera known to the system
  useEffect(() => void setDefaultCamera(ref.current), []);
  // Update it every frame
  useFrame((scene, camera) => {
    rig.current.position.y = MathUtils.lerp(rig.current.position.y, -vY * 5, 0.01);
    rig.current.rotation.y = MathUtils.lerp(rig.current.rotation.y, -vX, 0.02);
    rig.current.rotation.x = MathUtils.lerp(rig.current.rotation.z, -vY * 0.2, 0.1);

    ref.current.rotation.y = MathUtils.lerp(ref.current.rotation.y, -vX * 0.25, 0.02);

  });
  return (
    <group ref={rig}>
      <pointLight position={[0, 0, 40]} />
      <perspectiveCamera ref={ref} {...props} />
    </group>
  );
}


const ThreeDScene = () => {
  const isMobile = useMemo(() => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent), []);
  const clearColor = useMemo(() => isMobile ? 0x17041B : 0x040105, [isMobile]);

  return <Canvas
    resize
    concurrent
    pixelRatio={Math.min(2, isMobile ? window.devicePixelRatio : 1)}
    onCreated={({ gl }) => {
      gl.toneMapping = THREE.Uncharted2ToneMapping;
      gl.setClearColor(new THREE.Color(clearColor));
    }}
  >
    <fog attach="fog" args={isMobile ? [clearColor, 20, 50] : [clearColor, 5, 50]} />
    <ambientLight color={0x222244} />
    <Suspense fallback={null}>
      <Virus scale={[20, 20, 20]} position={[0, -5, -50]} />
    </Suspense>
    <PointField amount={isMobile ? 2000 : 8000} />
    <Camera position={[0, 0, 60]} rotation={[0, 0.1, 0]} isMobile={isMobile} />
    {!isMobile && <Effects />}
  </Canvas>
}



const App = () => {

  const { config, save, loading } = useConfig("data-config", {
    region: "Canada",
    province: 0,
    flags: [
      DF.TOTAL_CASES,
      DF.NEW_CASES,
      DF.NEW_DEATHS,
      DF.NEW_RECOVERED
    ]
  });

return <>
  <ThreeDScene />
  {!loading && config.region && <DataViz defaultFlags={config.flags} defaultRegion={config.region} defaultProvince={config.province} save={save} /> }
</>
}



const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);
