import React, { useMemo } from "react";

import { useTransition, animated } from "react-spring";

const DataPoint = ({ value, title, className, flag }) => {
  const items = useMemo(
    () =>
      value
        .toString()
        .split("")
        .map((v, i) => {
          const item = {
            key: i,
            text: v
          };
          return item;
        }),
    [value]
  );
  const transitions = useTransition(items, item=>item.key, {
    from: {
      transform: "translate3d(0,-40px,0)",
      opacity: 0,
      color: "rgb(20,20,40)",
    },
    enter: {
      transform: "translate3d(0, 0px,0)",
      opacity: 1,
      color: "rgb(255,255,255)",
    },
    leave: {
      transform: "translate3d(0,40px,0)",
      opacity: 0,
      color: "rgb(20,20,40)",
    }
  });
  return (
    <article className={`data ${className}`}>
      <h2>{title}</h2>
      <div className="value">
        {transitions.map(({ item, props, key }) => (
          <animated.div key={key} style={props}>
            {item.text} 
          </animated.div>
        ))}
      </div>
    </article>
  );
};

export const DATA_FLAGS = {
  TOTAL_CASES: "C",
  NEW_CASES: "c+",
  ACTIVE_CASES: "A",
  NEW_ACTIVE_CASES: "a+",
  TOTAL_DEATHS: "D",
  NEW_DEATHS: "d+",
  RECOVERED: "R",
  NEW_RECOVERED: "r+",
  FATLITY_RATE: "F"
};

export const DATA_KEYS = Object.keys(DATA_FLAGS);

export const DATA_TITLES = {
  TOTAL_CASES: "Total Cases",
  NEW_CASES: "New Cases",
  ACTIVE_CASES: "Active Cases",
  NEW_ACTIVE_CASES: "New Active Cases",
  TOTAL_DEATHS: "Total Deaths",
  NEW_DEATHS: "New Deaths",
  RECOVERED: "Recovered",
  NEW_RECOVERED: "New Recovered",
  FATLITY_RATE: "Fatality Rate"
};

export const DATA_VARS = {
    TOTAL_CASES: "confirmed",
    NEW_CASES: "confirmed_diff",
    ACTIVE_CASES: "active",
    NEW_ACTIVE_CASES: "active_diff",
    TOTAL_DEATHS: "deaths",
    NEW_DEATHS: "deaths_diff",
    RECOVERED: "recovered",
    NEW_RECOVERED: "recovered_diff",
    FATLITY_RATE: "fatality_rate"
}

export function ToggleReport({ flags, onClick = e => console.log(e) }) {
  return Object.keys(DATA_FLAGS).map((key,i) => {
    const title = DATA_TITLES[key];
    const flag = DATA_FLAGS[key];
    const set = flags.includes(flag);

    return (
      <>
        <div
          key={flag+i}
          className={`toggle ${set ? "on" : "off"}`}
          onClick={() => onClick(flag, !set)}
        >
          {title} <span>{set ? "ON" : "OFF"} </span>
        </div>
      </>
    );
  });
}

export function Report(props) {
  const { flags } = props;
  return DATA_KEYS.filter(key=>flags.includes(DATA_FLAGS[key])).map((key,i) => {
    const title = DATA_TITLES[key];
    const value = props[DATA_VARS[key]];
    return <DataPoint key={`${key}${DATA_FLAGS[key]}`} flag={DATA_FLAGS[key]} title={title} value={value} />
  });
}
