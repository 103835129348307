import { useEffect, useState } from 'react';

const useMouse = (ref) => {
    const [state, setState] = useState({
        docX: 0,
        docY: 0,
        posX: 0,
        posY: 0,
        elX: 0,
        elY: 0,
        elH: 0,
        elW: 0,
        vX: 0.5,
        vY: 0.5,
    })
    useEffect(() => {

        const moveHandler = (event) => {
            var halfWidth = (window.innerWidth*0.5);
            var halfHeight = (window.innerHeight*0.5)

            if (ref && ref.current) {
                const { left, top, width: elW, height: elH } = ref.current.getBoundingClientRect();
                const posX = left + window.pageXOffset;
                const posY = top + window.pageYOffset;
                const elX = event.pageX - posX;
                const elY = event.pageY - posY;
                const vX = (event.pageX - halfWidth) / halfWidth;
                const vY = (event.pageY - halfHeight) / halfHeight;

                setState({
                    docX: event.pageX,
                    docY: event.pageY,
                    posX,
                    posY,
                    elX,
                    elY,
                    elH,
                    elW,
                    vX,
                    vY,
                });
            }
        };

        document.addEventListener('mousemove', moveHandler);

        return () => {
            document.removeEventListener('mousemove', moveHandler);
        };
    }, [ref]);

    return state;

}

export default useMouse;